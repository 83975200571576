<template>
  <!-- Page Content  -->
  <b-container fluid>
    <!-- BEGIN:Modal Create -->
    <b-modal
      id="modal-create"
      v-model="isModalCreate"
      size="lg"
      centered
      title="Tambah Data Mitra"
      title-class="text-white font-weight-bold"
      no-close-on-backdrop
      hide-footer
      header-bg-variant="primary"
      header-text-variant="white"
    >
      <b-form class="px-4">
        <b-form-group>
          <label class="block" for="namaMitra"
            >Nama Mitra: <span class="text-danger">*</span></label
          >
          <input-primary
            id="namaMitra"
            v-model="form.namaMitra"
            required
            max-length="100"
          />
        </b-form-group>
        <b-form-group>
          <label class="block" for="alamatMitra"
            >Alamat Mitra: <span class="text-danger">*</span></label
          >
          <b-form-textarea
            id="alamatMitra"
            v-model="form.alamatMitra"
            required
            rows="3"
          ></b-form-textarea>
        </b-form-group>
        <b-form-group>
          <label class="block" for="tglBerdiri"
            >Tanggal Berdiri Mitra: <span class="text-danger">*</span></label
          >
          <flat-pickr
            id="tglBerdiri"
            class="form-control bg-white"
            :config="basicConfig"
            v-model="form.tglBerdiri"
            required
            placeholder="Tanggal Berdiri Mitra"
          ></flat-pickr>
        </b-form-group>
        <b-form-group>
          <label class="block" for="telp"
            >Telepon Mitra: <span class="text-danger">*</span></label
          >
          <input-primary
            id="telp"
            v-model="form.telp"
            required
            phone
            max-length="15"
          />
        </b-form-group>
        <b-form-group>
          <label class="block" for="email"
            >Email Mitra: <span class="text-danger">*</span></label
          >
          <input-primary
            type="email"
            maxLength="100"
            :regex="/\s/gi"
            regexMessage="Tidak dapat memuat spasi"
            v-model="form.email"
            @input="inputEmailMitra()"
            lowerCase
            required
            id="email"
            :isError="!isValidEmailMitra"
          />
          <collapse-transition>
            <div
              class="px-2 text-danger mt-2"
              v-show="isError.email.validEmailMitra"
            >
              Email harus mengandung karakter @
            </div>
          </collapse-transition>
        </b-form-group>
        <b-form-group>
          <input-datalists
            id="kodeGroup"
            class="w-full text-sm"
            placeholder="Pilih Group Mitra"
            v-model="form.kodeGroup"
            label="dataKodeGroupMitra"
            required
            :options="
              (listDataGroupMitra &&
                listDataGroupMitra.map(data => {
                  return {
                    dataKodeGroupMitra: data.kodeGroup + ` | ` + data.namaGroup,
                    kodeGroupMitra: data.kodeGroup,
                    idGroupMitra: parseInt(data.kodeGroup)
                  }
                })) ||
                []
            "
            :clearable="false"
          >
            <template #header>
              <label class="block"
                >Group Mitra: <span class="text-danger">*</span></label
              >
            </template>
          </input-datalists>
        </b-form-group>
        <b-row class="mt-5">
          <b-col>
            <b-btn class="btn-block" @click="closeModalCreate">Batal</b-btn>
          </b-col>
          <b-col>
            <b-btn
              class="btn-block"
              variant="success"
              @click="createData"
              :disabled="!isValidForm"
              >Simpan</b-btn
            >
          </b-col>
        </b-row>
      </b-form>
    </b-modal>
    <!-- ./END:Modal Create -->
    <!-- BEGIN:Modal Edit -->
    <b-modal
      id="modal-edit"
      v-model="isModalEdit"
      size="lg"
      centered
      title="Ubah Data Mitra"
      title-class="text-white font-weight-bold"
      no-close-on-backdrop
      hide-footer
      header-bg-variant="primary"
      header-text-variant="white"
    >
      <b-form class="px-4">
        <b-form-group
          label="Kode Mitra:"
          label-for="kodeMitra"
          label-class="text-dark"
        >
          <input-primary
            id="kodeMitra"
            v-model="modal.kodeMitra"
            required
            isDetail
            type="number"
            max-length="30"
          />
        </b-form-group>
        <b-form-group
          label="Nama Mitra:"
          label-for="namaMitra"
          label-class="text-dark"
        >
          <input-primary
            id="namaMitra"
            v-model="modal.namaMitra"
            required
            max-length="100"
          />
        </b-form-group>
        <b-form-group
          label="Alamat Mitra:"
          label-for="alamatMitra"
          label-class="text-dark"
        >
          <b-form-textarea
            id="alamatMitra"
            v-model="modal.alamatMitra"
            rows="3"
          ></b-form-textarea>
        </b-form-group>
        <b-form-group
          label="Tanggal Berdiri Mitra:"
          label-for="tglBerdiri"
          label-class="text-dark"
        >
          <flat-pickr
            id="tglBerdiri"
            class="form-control bg-white"
            :config="basicConfig"
            v-model="modal.tglBerdiri"
            placeholder="Tanggal Berdiri Mitra"
          ></flat-pickr>
        </b-form-group>
        <b-form-group label="Telepon Mitra:" label-for="telp">
          <input-primary
            id="telp"
            v-model="modal.telp"
            required
            phone
            max-length="15"
          />
        </b-form-group>
        <b-form-group label="Email Mitra:" label-for="email">
          <input-primary
            type="email"
            maxLength="100"
            :regex="/\s/gi"
            regexMessage="Tidak dapat memuat spasi"
            v-model="modal.email"
            @input="inputEmailMitra()"
            lowerCase
            required
            id="email"
            :isError="!isValidEmailMitra"
          />
          <collapse-transition>
            <div
              class="px-2 text-danger mt-2"
              v-show="isError.email.validEmailMitra"
            >
              Email harus mengandung karakter @
            </div>
          </collapse-transition>
        </b-form-group>
        <b-form-group>
          <input-datalists
            id="kodeGroup"
            class="w-full text-sm"
            placeholder="Pilih Group Mitra"
            v-model="modal.kodeGroup"
            label="dataKodeGroupMitra"
            required
            :options="
              (listDataGroupMitra &&
                listDataGroupMitra.map(data => {
                  return {
                    dataKodeGroupMitra: data.kodeGroup + ` | ` + data.namaGroup,
                    kodeGroupMitra: data.kodeGroup,
                    idGroupMitra: parseInt(data.kodeGroup)
                  }
                })) ||
                []
            "
            :clearable="false"
          >
            <template #header>
              <label class="block"
                >Group Mitra: <span class="text-danger">*</span></label
              >
            </template>
          </input-datalists>
        </b-form-group>
        <b-form-group label="Status Mitra:" label-for="idStatusMitra">
          <b-form-radio
            inline
            id="idStatusMitra1"
            v-model="modal.idStatusMitra"
            value="1"
            name="idStatusMitra"
            >Aktif</b-form-radio
          >
          <b-form-radio
            inline
            id="idStatusMitra2"
            v-model="modal.idStatusMitra"
            value="0"
            name="idStatusMitra"
            >Tidak Aktif</b-form-radio
          >
        </b-form-group>
        <b-row class="mt-5">
          <b-col>
            <b-btn class="btn-block" @click="closeModalEdit">Batal</b-btn>
          </b-col>
          <b-col>
            <b-btn
              class="btn-block"
              variant="success"
              @click="editData"
              :disabled="!isValidModal"
              >Simpan</b-btn
            >
          </b-col>
        </b-row>
      </b-form>
    </b-modal>
    <!-- ./END:Modal Edit -->
    <!-- BEGIN:Modal Delete -->
    <b-modal
      id="modal-delete"
      v-model="isModalDelete"
      centered
      title="Hapus Data Mitra"
      title-class="text-white font-weight-bold"
      no-close-on-backdrop
      hide-footer
      header-bg-variant="danger"
      header-text-variant="white"
    >
      <b-form class="px-4">
        <h5 class="text-center">
          Apakah anda yakin ingin menghapus data<br /><b
            >{{ modal.kodeMitra }} | {{ modal.namaMitra }}</b
          >?
        </h5>
        <b-row class="mt-5">
          <b-col>
            <b-btn class="btn-block" @click="isModalDelete = false"
              >Batal</b-btn
            >
          </b-col>
          <b-col>
            <b-btn class="btn-block" variant="success" @click="deleteData"
              >Hapus</b-btn
            >
          </b-col>
        </b-row>
      </b-form>
    </b-modal>
    <!-- ./END:Modal Delete -->
    <!-- BEGIN:Modal Kontak Mitra -->
    <b-modal
      id="modal-kontak-mitra"
      v-model="isModalKontak"
      size="xl"
      centered
      title="Kontak Mitra"
      title-class="text-white font-weight-bold"
      no-close-on-backdrop
      hide-footer
      header-bg-variant="primary"
      header-text-variant="white"
    >
      <b-form class="px-4">
        <b-form-group
          label="Kode Mitra:"
          label-for="kodeMitra"
          label-class="text-dark"
        >
          <input-primary
            id="kodeMitra"
            v-model="kontak.kodeMitra"
            required
            isDetail
            type="number"
            max-length="30"
          />
        </b-form-group>
        <b-form-group
          label="Nama Kontak:"
          label-for="kontakPerson"
          label-class="text-dark"
        >
          <input-primary
            id="kontakPerson"
            v-model="kontak.kontakPerson"
            required
            max-length="100"
          />
        </b-form-group>
        <b-form-group
          label="Tempat Lahir:"
          label-for="tempatLahir"
          label-class="text-dark"
        >
          <input-primary
            id="tempatLahir"
            v-model="kontak.tempatLahir"
            required
            max-length="100"
          />
        </b-form-group>
        <b-form-group
          label="Tanggal Lahir:"
          label-for="tanggalLahir"
          label-class="text-dark"
        >
          <flat-pickr
            id="tanggalLahir"
            class="form-control bg-white"
            :config="basicConfig"
            v-model="kontak.tanggalLahir"
            placeholder="Tanggal Lahir"
          ></flat-pickr>
        </b-form-group>
        <b-form-group label="Email:" label-for="Email">
          <input-primary
            type="email"
            maxLength="100"
            :regex="/\s/gi"
            regexMessage="Tidak dapat memuat spasi"
            v-model="kontak.Email"
            @input="inputEmailKontak()"
            lowerCase
            required
            id="Email"
            :isError="!isValidEmailKontak"
          />
          <collapse-transition>
            <div
              class="px-2 text-danger mt-2"
              v-show="isError.Email.validEmailKontak"
            >
              Email harus mengandung karakter @
            </div>
          </collapse-transition>
        </b-form-group>
        <b-form-group label="No Handphone:" label-for="noHp">
          <input-primary
            id="noHp"
            v-model="kontak.noHp"
            required
            phone
            max-length="13"
          />
        </b-form-group>
        <b-form-group
          label="Jabatan:"
          label-for="Jabatan"
          label-class="text-dark"
        >
          <input-primary
            id="Jabatan"
            v-model="kontak.Jabatan"
            required
            max-length="100"
          />
        </b-form-group>
        <b-form-group>
          <input-datalists
            id="kodeAgama"
            class="w-full text-sm"
            placeholder="Pilih Agama"
            v-model="kontak.kodeAgama"
            label="dataKodeAgama"
            required
            :options="
              (listDataAgama &&
                listDataAgama.map(data => {
                  return {
                    dataKodeAgama: data.kodeAgama + ` | ` + data.namaAgama,
                    kodeAgama: data.kodeAgama,
                    idAgama: parseInt(data.kodeAgama)
                  }
                })) ||
                []
            "
            :clearable="false"
          >
            <template #header>
              <label class="block"
                >Group Mitra: <span class="text-danger">*</span></label
              >
            </template>
          </input-datalists>
        </b-form-group>
        <b-row class="mt-5">
          <b-col>
            <b-btn class="btn-block" @click="closeModalKontak">Kembali</b-btn>
          </b-col>
          <b-col>
            <b-btn
              class="btn-block"
              variant="success"
              @click="createKontak"
              :disabled="!isValidKontak"
              >Simpan</b-btn
            >
          </b-col>
        </b-row>
      </b-form>
      <hr class="mt-4" />
      <div class="align-items-center px-4">
        <b-table
          responsive
          bordered
          :fields="theadKontak"
          :items="dataKontak"
          class="mt-2"
          :busy="isLoadingKontak"
          :per-page="perPageKontak"
          :current-page="currentPageKontak"
          @filtered="onFilteredKontak"
          show-empty
        >
          <template v-slot:cell(aksi)="data">
            <b-button
              class="btn-block"
              variant=" iq-bg-danger"
              v-b-tooltip="'Hapus Data'"
              size="sm"
              @click="showModalDeleteKontak(data.item)"
              ><i class="ri-delete-bin-line m-0"></i>Hapus</b-button
            >
          </template>
          <template #table-busy>
            <div class="text-center text-danger my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong>Loading...</strong>
            </div>
          </template>
          <template #empty>
            <div class="text-danger font-weight-bold mx-auto text-center">
              Data tidak ditemukan
            </div>
          </template>
          <template #emptyfiltered>
            <div class="text-danger font-weight-bold mx-auto text-center">
              Data tidak ditemukan
            </div>
          </template>
        </b-table>
        <b-row>
          <b-col>
            <b-form-group
              label="Per page:"
              label-for="per-page-select"
              label-size="sm"
              class="d-flex"
            >
              <b-form-select
                class="ml-2"
                id="per-page-select"
                v-model="perPageKontak"
                :options="pageOptionsKontak"
                size="sm"
              ></b-form-select>
            </b-form-group>
          </b-col>
          <b-col>
            <b-pagination
              class="float-right"
              v-model="currentPageKontak"
              :total-rows="totalRowsKontak"
              :per-page="perPageKontak"
            ></b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-modal>
    <!-- ./END:Modal Kontak Mitra -->
    <!-- BEGIN:Modal Delete Kontak -->
    <b-modal
      id="modal-delete"
      v-model="isModalDeleteKontak"
      centered
      title="Hapus Data Kontak"
      title-class="text-white font-weight-bold"
      no-close-on-backdrop
      hide-footer
      header-bg-variant="danger"
      header-text-variant="white"
    >
      <b-form class="px-4">
        <h5 class="text-center">
          Apakah anda yakin ingin menghapus data<br /><b
            >{{ kontak.kontakPerson }} | {{ kontak.noHp }}</b
          >?
        </h5>
        <b-row class="mt-5">
          <b-col>
            <b-btn class="btn-block" @click="isModalDeleteKontak = false"
              >Batal</b-btn
            >
          </b-col>
          <b-col>
            <b-btn class="btn-block" variant="success" @click="deleteKontak"
              >Hapus</b-btn
            >
          </b-col>
        </b-row>
      </b-form>
    </b-modal>
    <!-- ./END:Modal Delete Kontak -->
    <b-row>
      <!-- BEGIN:Table -->
      <b-col md="12">
        <iq-card
          bodyClass="relative-background"
          class="iq-card-block iq-card-stretch iq-card-height"
        >
          <template v-slot:headerTitle>
            <h4 class="card-title">Daftar Profile Mitra</h4>
          </template>
          <template v-slot:body>
            <div class="align-items-center">
              <b-row>
                <b-col>
                  <b-form-input
                    class="w-50"
                    id="filter-input"
                    v-model="filter"
                    type="search"
                    placeholder="Cari Data"
                  ></b-form-input>
                </b-col>
                <b-col>
                  <div class="d-inline-block float-right">
                    <b-button @click="showModalCreate" variant="primary"
                      >Tambah Data<i class="ri-add-line ml-2"></i
                    ></b-button>
                  </div>
                </b-col>
              </b-row>
              <b-table
                responsive
                bordered
                :fields="thead"
                :items="listData"
                class="mt-2"
                :busy="isLoading"
                :filter="filter"
                :per-page="perPage"
                :current-page="currentPage"
                @filtered="onFiltered"
                show-empty
              >
                <template v-slot:cell(kontak_mitra)="listData">
                  <b-link
                    variant=" iq-bg-primary"
                    v-b-tooltip="'Kontak Mitra'"
                    size="sm"
                    @click="showModalKontak(listData.item)"
                    ><i class="ri-phone-fill mr-1"></i>Kontak</b-link
                  >
                </template>
                <template v-slot:cell(idStatusMitra)="listData">
                  <span>{{
                    listData.item.idStatusMitra == 1 ? 'Aktif' : 'Tidak Aktif'
                  }}</span>
                </template>
                <template v-slot:cell(aksi)="listData">
                  <b-button
                    class="mx-1"
                    variant=" iq-bg-success"
                    size="sm"
                    @click="showModalEdit(listData.item)"
                    ><i class="ri-ball-pen-fill m-0"></i>Ubah</b-button
                  >
                  <b-button
                    class="mx-1"
                    variant=" iq-bg-danger"
                    size="sm"
                    @click="
                      showModalDelete(
                        listData.item.idMitra,
                        listData.item.kodeMitra,
                        listData.item.namaMitra
                      )
                    "
                    ><i class="ri-delete-bin-line m-0"></i>Hapus</b-button
                  >
                </template>
                <template #table-busy>
                  <div class="text-center text-danger my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong>Loading...</strong>
                  </div>
                </template>
                <template #empty>
                  <div class="text-danger font-weight-bold mx-auto text-center">
                    Data tidak ditemukan
                  </div>
                </template>
                <template #emptyfiltered>
                  <div class="text-danger font-weight-bold mx-auto text-center">
                    Data tidak ditemukan
                  </div>
                </template>
              </b-table>
              <b-row>
                <b-col>
                  <b-form-group
                    label="Per page:"
                    label-for="per-page-select"
                    label-size="sm"
                    class="d-flex"
                  >
                    <b-form-select
                      class="ml-2"
                      id="per-page-select"
                      v-model="perPage"
                      :options="pageOptions"
                      size="sm"
                    ></b-form-select>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-pagination
                    class="float-right"
                    v-model="currentPage"
                    :total-rows="totalRows"
                    :per-page="perPage"
                  ></b-pagination>
                </b-col>
              </b-row>
            </div>
          </template>
        </iq-card>
      </b-col>
      <!-- ./END:Table -->
    </b-row>
  </b-container>
</template>
<script>
// import { core } from '@/config/pluginInit'
import axios from 'axios'
import { mapState } from 'vuex'

export default {
  name: 'master.mitra',
  computed: {
    ...mapState('authService', ['token']),
    isValidForm() {
      const status =
        this.form.namaMitra &&
        this.form.alamatMitra &&
        this.form.tglBerdiri &&
        this.form.telp &&
        this.form.email &&
        this.form.kodeGroup !== null
      return status
    },
    isValidModal() {
      const status =
        this.modal.namaMitra &&
        this.modal.alamatMitra &&
        this.modal.tglBerdiri &&
        this.modal.telp &&
        this.modal.email &&
        this.modal.kodeGroup &&
        this.modal.idStatusMitra !== null
      return status
    },
    isValidKontak() {
      const status =
        this.kontak.kodeMitra &&
        this.kontak.kontakPerson &&
        this.kontak.tempatLahir &&
        this.kontak.tanggalLahir &&
        this.kontak.Email &&
        this.kontak.noHp &&
        this.kontak.Jabatan &&
        this.kontak.kodeAgama !== null
      return status
    },
    isValidEmailMitra() {
      const { validEmailMitra } = this.isError.email
      return !validEmailMitra
    },
    isValidEmailKontak() {
      const { validEmailKontak } = this.isError.Email
      return !validEmailKontak
    }
  },
  async mounted() {
    /** Innit data */
    this.innitData()
  },
  methods: {
    /**
     * Get data kategori PKS
     */
    async getDataMitra_() {
      const result = await axios({
        url: `${process.env.VUE_APP_URL_PUBLIC}/getListMasterPks?type=${this.typeData}`,
        method: 'GET',
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: 'Bearer ' + this.token
        }
      })
        .then(res => {
          this.listData = res.data
          /** Set the initial number of items */
          this.totalRows = this.listData.length
        })
        .catch(err => {
          console.log('ERROR', err)
          throw new Error(err)
        })
      return result
    },
    /**
     * Get data kontak by kode mitra
     */
    async getDataKontak(kodeMitra) {
      this.isLoadingKontak = true
      const result = await axios({
        url: `${process.env.VUE_APP_URL_PUBLIC}/getDataMasterPks?type=cp&kodeMaster=${kodeMitra}`,
        method: 'GET',
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: 'Bearer ' + this.token
        }
      })
        .then(res => {
          this.dataKontak = res.data
          /** Set the initial number of items */
          this.totalRowsKontak = this.dataKontak.length
        })
        .catch(err => {
          console.log('ERROR', err)
          throw new Error(err)
        })
      this.isLoadingKontak = false
      return result
    },
    /**
     * Get data group mitra
     */
    async getDataGroupMitra_() {
      const result = await axios({
        url: `${process.env.VUE_APP_URL_PUBLIC}/getListMasterPks?type=groupMitra`,
        method: 'GET',
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: 'Bearer ' + this.token
        }
      })
        .then(res => {
          this.listDataGroupMitra = res.data
        })
        .catch(err => {
          console.log('ERROR', err)
          throw new Error(err)
        })
      return result
    },
    /**
     * Get data agama
     */
    async getDataAgama_() {
      const result = await axios({
        url: `${process.env.VUE_APP_URL_PUBLIC}/getListMasterPks?type=agama`,
        method: 'GET',
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: 'Bearer ' + this.token
        }
      })
        .then(res => {
          this.listDataAgama = res.data
        })
        .catch(err => {
          console.log('ERROR', err)
          throw new Error(err)
        })
      return result
    },
    /**
     * Trigger pagination to update the number of buttons/pages due to filtering
     */
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    onFilteredKontak(filteredItems) {
      this.totalRowsKontak = filteredItems.length
      this.currentPage = 1
    },
    /**
     * Input Email
     */
    inputEmailMitra() {
      this.isError.email = {
        validEmailMitra: false
      }
      if (this.form.email !== null) {
        if (!this.form.email.includes('@')) {
          this.isError.email.validEmailMitra = true
        }
      }
    },
    inputEmailKontak() {
      this.isError.Email = {
        validEmailKontak: false
      }
      if (this.kontak.Email !== null) {
        if (!this.kontak.Email.includes('@')) {
          this.isError.Email.validEmailKontak = true
        }
      }
    },
    /**
     * Reset Value
     */
    resetForm() {
      this.form.namaMitra = null
      this.form.alamatMitra = null
      this.form.tglBerdiri = null
      this.form.telp = null
      this.form.email = null
      this.form.kodeGroup = null
    },
    resetModal() {
      this.modal.namaMitra = null
      this.modal.alamatMitra = null
      this.modal.tglBerdiri = null
      this.modal.telp = null
      this.modal.email = null
      this.modal.kodeGroup = null
      this.modal.idStatusMitra = null
    },
    resetKontak() {
      this.kontak.kontakPerson = null
      this.kontak.tempatLahir = null
      this.kontak.tanggalLahir = null
      this.kontak.Email = null
      this.kontak.noHp = null
      this.kontak.Jabatan = null
      this.kontak.kodeAgama = null
    },
    /**
     * Create Mitra
     */
    showModalCreate() {
      this.resetForm()
      this.isModalCreate = true
    },
    closeModalCreate() {
      this.resetForm()
      this.isModalCreate = false
    },
    async createData() {
      const bodyFormData = new FormData()
      bodyFormData.append('type', this.typeData)
      bodyFormData.append('namaMitra', this.form.namaMitra)
      bodyFormData.append('alamatMitra', this.form.alamatMitra)
      bodyFormData.append('tanggalBerdiriMitra', this.form.tglBerdiri)
      bodyFormData.append('teleponMitra', this.form.telp)
      bodyFormData.append('emailMitra', this.form.email)
      bodyFormData.append('idGroup', this.form.kodeGroup.idGroupMitra)
      bodyFormData.append('kodeGroup', this.form.kodeGroup.kodeGroupMitra)
      bodyFormData.append('idStatusMitra', 1)
      await axios({
        url: `${process.env.VUE_APP_URL_PUBLIC}/tambahDataMasterPks`,
        method: 'POST',
        data: bodyFormData,
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: 'Bearer ' + this.token
        }
      })
        .then(res => {
          this.$toast.success(res.data.message)
        })
        .catch(err => {
          this.isModalCreate = false
          this.$toast.error('Gagal simpan data')
          console.log('ERROR', err)
          throw new Error(err)
        })
      this.innitData()
      this.isModalCreate = false
    },
    /**
     * Edit Mitra
     */
    showModalEdit(data) {
      this.resetModal()
      console.log('Data ', data)
      this.modal.idMitra = data.idMitra
      this.modal.kodeMitra = data.kodeMitra
      this.modal.namaMitra = data.namaMitra
      this.modal.alamatMitra = data.alamatMitra
      this.modal.tglBerdiri = data.tglBerdiri
      this.modal.telp = data.telp
      this.modal.email = data.email
      this.modal.idGroup = data.idGroup
      this.modal.kodeGroup = data.kodeGroup
      this.modal.idStatusMitra = parseInt(data.idStatusMitra)
      console.log(this.modal.idStatusMitra)
      this.isModalEdit = true
    },
    closeModalEdit() {
      this.resetModal()
      this.isModalEdit = false
    },
    async editData() {
      const kodeGroup = this.modal.kodeGroup.kodeGroupMitra
        ? this.modal.kodeGroup.kodeGroupMitra
        : this.modal.kodeGroup
      const idGroup = this.modal.kodeGroup.idGroupMitra
        ? this.modal.kodeGroup.idGroupMitra
        : this.modal.idGroup
      const idStatusMitra = parseInt(this.modal.idStatusMitra)
      const bodyFormData = new FormData()
      bodyFormData.append('type', this.typeData)
      bodyFormData.append('idMitra', this.modal.idMitra)
      bodyFormData.append('kodeMitra', this.modal.kodeMitra)
      bodyFormData.append('namaMitra', this.modal.namaMitra)
      bodyFormData.append('alamatMitra', this.modal.alamatMitra)
      bodyFormData.append('tanggalBerdiriMitra', this.modal.tglBerdiri)
      bodyFormData.append('teleponMitra', this.modal.telp)
      bodyFormData.append('emailMitra', this.modal.email)
      bodyFormData.append('idGroup', idGroup)
      bodyFormData.append('kodeGroup', kodeGroup)
      bodyFormData.append('idStatusMitra', idStatusMitra)
      await axios({
        url: `${process.env.VUE_APP_URL_PUBLIC}/ubahDataMasterPks`,
        method: 'POST',
        data: bodyFormData,
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: 'Bearer ' + this.token
        }
      })
        .then(res => {
          this.$toast.success(res.data.message)
        })
        .catch(err => {
          this.isModalEdit = false
          this.$toast.error('Gagal ubah data')
          console.log('ERROR', err)
          throw new Error(err)
        })
      this.innitData()
      this.isModalEdit = false
    },
    /**
     * Delete Mitra
     */
    showModalDelete(id, kode, nama) {
      this.modal.idMitra = id
      this.modal.kodeMitra = kode
      this.modal.namaMitra = nama
      this.isModalDelete = true
    },
    async deleteData() {
      const bodyFormData = new URLSearchParams()
      bodyFormData.append('type', this.typeData)
      bodyFormData.append('idMitra', this.modal.idMitra)
      await axios({
        url: `${process.env.VUE_APP_URL_PUBLIC}/hapusDataMasterPks`,
        method: 'DELETE',
        data: bodyFormData,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          Authorization: 'Bearer ' + this.token
        }
      })
        .then(res => {
          this.$toast.success(res.data.message)
        })
        .catch(err => {
          this.isModalDelete = false
          this.$toast.error('Gagal hapus data')
          console.log('ERROR', err)
          throw new Error(err)
        })
      this.innitData()
      this.isModalDelete = false
    },
    /**
     * Kontak Mitra
     */
    showModalKontak(data) {
      this.resetKontak()
      this.kontak.kodeMitra = data.kodeMitra
      this.kontak.namaMitra = data.namaMitra
      this.kontak.alamatMitra = data.alamatMitra
      this.kontak.telp = data.telp
      this.kontak.email = data.email
      this.kontak.kodeGroup = data.kodeGroup
      this.getDataKontak(this.kontak.kodeMitra)
      this.isModalKontak = true
    },
    closeModalKontak() {
      this.resetKontak()
      this.isModalKontak = false
    },
    async createKontak() {
      const bodyFormData = new FormData()
      bodyFormData.append('type', 'cp')
      bodyFormData.append('kodeMitra', this.kontak.kodeMitra)
      bodyFormData.append('kontakPerson', this.kontak.kontakPerson)
      bodyFormData.append('tempatLahir', this.kontak.tempatLahir)
      bodyFormData.append('tanggalLahir', this.kontak.tanggalLahir)
      bodyFormData.append('email', this.kontak.Email)
      bodyFormData.append('noHp', this.kontak.noHp)
      bodyFormData.append('jabatan', this.kontak.Jabatan)
      bodyFormData.append('kodeAgama', this.kontak.kodeAgama.kodeAgama)
      await axios({
        url: `${process.env.VUE_APP_URL_PUBLIC}/tambahDataMasterPks`,
        method: 'POST',
        data: bodyFormData,
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: 'Bearer ' + this.token
        }
      })
        .then(res => {
          this.$toast.success(res.data.message)
        })
        .catch(err => {
          console.log('ERROR', err)
          throw new Error(err)
        })
      this.getDataKontak(this.kontak.kodeMitra)
      this.resetKontak()
    },
    showModalDeleteKontak(data) {
      this.kontak.kodeMitra = data.kodeMitra
      this.kontak.kontakPerson = data.kontakPerson
      this.kontak.noHp = data.noHp
      this.isModalDeleteKontak = true
    },
    async deleteKontak() {
      const bodyFormData = new URLSearchParams()
      bodyFormData.append('type', 'cp')
      bodyFormData.append('kodeMitra', this.kontak.kodeMitra)
      bodyFormData.append('kontakPerson', this.kontak.kontakPerson)
      await axios({
        url: `${process.env.VUE_APP_URL_PUBLIC}/hapusDataMasterPks`,
        method: 'DELETE',
        data: bodyFormData,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          Authorization: 'Bearer ' + this.token
        }
      })
        .then(res => {
          this.$toast.success(res.data.message)
        })
        .catch(err => {
          console.log('ERROR', err)
          throw new Error(err)
        })
      this.getDataKontak(this.kontak.kodeMitra)
      this.resetKontak()
      this.isModalDeleteKontak = false
    },
    /**
     * Init Data
     */
    async innitData() {
      /** Get Data Mitra */
      this.isLoading = true
      await Promise.all([this.getDataMitra_()])
      this.isLoading = false
      /** Get List Data */
      await Promise.all([this.getDataGroupMitra_(), this.getDataAgama_()])
    }
  },
  data() {
    return {
      typeData: 'mitra',
      // Tabel Options
      isLoading: false,
      filter: null,
      perPage: 5,
      pageOptions: [5, 10, 15, { value: 100, text: '100' }],
      currentPage: 1,
      totalRows: 1,
      // Tabel Kontak Options
      isLoadingKontak: false,
      perPageKontak: 5,
      pageOptionsKontak: [5, 10, 15, { value: 100, text: 'Semua' }],
      currentPageKontak: 1,
      totalRowsKontak: 1,
      // isModal
      isModalCreate: false,
      isModalEdit: false,
      isModalDelete: false,
      isModalKontak: false,
      isModalDeleteKontak: false,
      // isError
      isError: {
        email: { validEmailMitra: false },
        Email: { validEmailKontak: false }
      },
      // Date Picker
      /**
       * Date Picker
       ** Menggunakan "static: true" agar dibungkus ke dalam flatpickr-wrapper
       ** Sehingga bisa edit tahun saat berada di dalam modal bootstrap
       */
      basicConfig: {
        altInput: true,
        altFormat: 'd-m-Y',
        dateFormat: 'Y-m-d',
        static: true
      },
      // Form
      form: {
        namaMitra: null,
        alamatMitra: null,
        tglBerdiri: null,
        telp: null,
        email: null,
        kodeGroup: null
      },
      // Modal
      modal: {
        idMitra: null,
        kodeMitra: null,
        namaMitra: null,
        alamatMitra: null,
        tglBerdiri: null,
        telp: null,
        email: null,
        idGroup: null,
        kodeGroup: null,
        idStatusMitra: null
      },
      // Kontak
      kontak: {
        kodeMitra: null,
        kontakPerson: null,
        tempatLahir: null,
        tanggalLahir: null,
        Email: null,
        noHp: null,
        Jabatan: null,
        kodeAgama: null
      },
      // Data list agama
      listDataAgama: [],
      // Data list group mitra
      listDataGroupMitra: [],
      // Table
      thead: [
        { label: 'Kontak Mitra', key: 'kontak_mitra', class: 'text-center' },
        {
          label: 'Kode Mitra',
          key: 'kodeMitra',
          class: 'text-center',
          sortable: true
        },
        {
          label: 'Nama Mitra',
          key: 'namaMitra',
          class: 'text-left w-25',
          sortable: true
        },
        {
          label: 'Alamat Mitra',
          key: 'alamatMitra',
          class: 'text-left w-25',
          sortable: true
        },
        {
          label: 'Tanggal Berdiri',
          key: 'tglBerdiri',
          class: 'text-center',
          sortable: true
        },
        {
          label: 'Email',
          key: 'email',
          class: 'text-center',
          sortable: true
        },
        {
          label: 'Telepon',
          key: 'telp',
          class: 'text-center',
          sortable: true
        },
        {
          label: 'Kode Group',
          key: 'kodeGroup',
          class: 'text-center',
          sortable: true
        },
        {
          label: 'Status Mitra',
          key: 'idStatusMitra',
          class: 'text-center',
          sortable: true
        },
        { label: 'Aksi', key: 'aksi', class: 'text-center w-25' }
      ],
      listData: [],
      // Table Kontak
      theadKontak: [
        {
          label: 'Nama Kontak',
          key: 'kontakPerson',
          class: 'text-center',
          sortable: true
        },
        {
          label: 'Tempat Lahir',
          key: 'tempatLahir',
          class: 'text-center',
          sortable: true
        },
        {
          label: 'Tanggal Lahir',
          key: 'tanggalLahir',
          class: 'text-center',
          sortable: true
        },
        {
          label: 'Email',
          key: 'Email',
          class: 'text-center',
          sortable: true
        },
        {
          label: 'No Handphone',
          key: 'noHp',
          class: 'text-center',
          sortable: true
        },
        {
          label: 'Jabatan',
          key: 'Jabatan',
          class: 'text-center',
          sortable: true
        },
        {
          label: 'Agama',
          key: 'kodeAgama',
          class: 'text-center',
          sortable: true
        },
        { label: 'Aksi', key: 'aksi', class: 'text-center' }
      ],
      dataKontak: []
    }
  }
}
</script>

<style>
.flatpickr-wrapper {
  width: 100%;
}
</style>
